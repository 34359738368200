import { render, staticRenderFns } from "./PostponementViewForm.vue?vue&type=template&id=62d5cfec&"
import script from "./PostponementViewForm.vue?vue&type=script&lang=ts&"
export * from "./PostponementViewForm.vue?vue&type=script&lang=ts&"
import style0 from "./PostponementViewForm.vue?vue&type=style&index=0&id=62d5cfec&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports